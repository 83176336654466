

<template>
  <f7-page name="book-detail">
    <f7-navbar title="Patrons" back-link="Back">
      <f7-link popup-open='#add-patron' slot="right">
        <f7-icon f7="plus"></f7-icon>
      </f7-link>
    </f7-navbar>




 <f7-col width="50">
        </f7-col>

    <!-- Popup -->
  <f7-popup id="book-check" @popup:close="(evt) => {close_camera(evt)}" @popup:open="(evt) => {read_barcode(evt)}">
    <f7-view>
      <f7-page>
        <f7-navbar title="Find by Book Id">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
         <div id ="vid-container">
          <video
            id="video"
            width="280"
            height="200"
          ></video>
        </div>
        <f7-block>
          <f7-input 
        id='book_id'
        type="text"
        placeholder="Book Id"
        :value="book_id"
        @input="book_id = $event.target.value"
      ></f7-input>
      <f7-button class='popup-elm' med fill raised color="blue" @click="(evt) => {check_in_book(evt,book_id,)}">Check In</f7-button>
      <f7-button class='popup-elm' med fill raised color="blue" @click="(evt) => {checkout_book(evt,book_id,)}">Check Out</f7-button>
        </f7-block>
         </f7-page>
    </f7-view>
  </f7-popup>

  <f7-popup id="add-patron" >
    <f7-view>
      <f7-page>
        <f7-navbar title="New Patron">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
         <div id ="vid-container">
           <canvas id='output' width='200' style="height:auto; border 1px solid black;"/>
           <label for="user_image" style="white-space: nowrap; text-decoration: underline;">Profile picture (Optional)</label>
           <input type="file" ref='user_image' capture=”camera” accept="image/*" id='user_image' style="display:none;" >
          
        </div>
        <f7-block>
          <f7-input 
        id='name'
        type="text"
        placeholder="Name"
        :value="new_patron_name"
        @input="new_patron_name = $event.target.value"
      ></f7-input>
      <f7-button class='popup-elm' med fill raised color="blue" @click="(evt) => {add_patron(evt,)}">Submit</f7-button>
        </f7-block>
         </f7-page>
    </f7-view>
  </f7-popup>


  <!-- <f7-block-title>Patrons</f7-block-title> -->
    <div class="gallery">
        <div class="gallery-panel"
            v-for="patron in patrons"
            :key="patron.name">
            <div @click="open_check(patron.name)">
                <img :src="patron.avatar">
                <p>{{patron.name}}</p>
            </div>
        </div>
    </div>
  </f7-page>
</template>

<style>
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    grid-gap: 1rem;
    max-width: 80rem;
    margin: 5rem auto;
    padding: 0 5rem;
  }
  .gallery-panel img {
    width: 100%;
    height: 6rem;
    object-fit: cover;
    border-radius: 0.75rem;
  }
  .popup-elm{
    width: 80%;
    margin: 1rem auto;
  }
  #vid-container{
    border: 1px solid white; 
    margin: 10px auto; 
    width: 280px;
  }
  #book_id{
    margin:5px auto;
    width: 50%;
  }
</style>

<script>

  import routes from '../js/routes.js';
  import utils from '../js/utils'
  import Vue from 'vue';
  import {BrowserBarcodeReader} from '@zxing/library'

 
  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Book Worm', // App name
          theme: 'auto', // Automatic theme detection
          cache: false,
          // App root data
          data: function () {
            return {
             
             
            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },

        readerSize: {
        width: 640,
        height: 480
        },
        detecteds: [],

        book_id: '',
        password: '',
        book: {},
        title: '',
        author:'',
        publisher:'',
        publish_date:'',
        codeReader: new BrowserBarcodeReader(),
        photos:[],
        patrons:[],
        current_patron: '',
        new_patron_name:'',
        user_image:'',


      }
    },
    methods: {
        get_patrons(){
          var vm = this;
          vm.$f7.dialog.preloader('Retrieving Patrons');
          var token = localStorage.userToken
          vm.$http.get(vm.$url.concat('api/v1/books/patrons/'),{
            headers:{Authorization: `Token ${token}`,}}, )
          .then(function (response) {
            vm.$f7.dialog.close();
            vm.patrons = []
            response.data.forEach(patron =>{
              if(!patron.avatar){
                patron.avatar = vm.$url.concat('media/patron/photos/avatar.png')
              }
              vm.patrons.push(patron)
            })
            vm.patrons.sort(function(a,b){
              return a.name.localeCompare(b.name)})
          })
          .catch(function (error) {
            console.log(error);
            vm.$f7.dialog.close();
          });
        },

        load_img(event){
          var vm = this
          console.log(event.target.files[0]);

          var file = event.target.files[0]

          //  Try resizing here
          var reader = new FileReader();  
            reader.onload = function(readerEvent) {
      
              var image = new Image();
              image.onload = function(imageEvent) {    
                  var max_size = 200;
                  var w = image.width;
                  var h = image.height;
                
                  if (w > h) {  if (w > max_size) { h*=max_size/w; w=max_size; }
                  } else     {  if (h > max_size) { w*=max_size/h; h=max_size; } }
                
                  // var canvas = document.createElement('canvas');
                  var canvas = document.getElementById('output')
                  canvas.width = w;
                  canvas.height = h;
                  canvas.getContext('2d').drawImage(image, 0, 0, w, h);
                    
                  if (file.type == "image/jpeg") {
                    var dataURL = canvas.toDataURL("image/jpeg", 1.0);
                  } else {
                    var dataURL = canvas.toDataURL("image/png");   
                  }

              }
                image.src = readerEvent.target.result;
                console.log("Result", readerEvent.target.result)
                vm.user_image = utils.dataURLToBlob(readerEvent.target.result)

              }
          reader.readAsDataURL(file);
          // End resizing here
           
        },

        add_patron(){
            var vm = this
            let data = new FormData()
            if (vm.user_image){
               let file = new File([vm.user_image], vm.new_patron_name+'.jpg' )
              console.log(file)
              data.append('avatar', file)
            }
           
            data.append('name', `${vm.new_patron_name}`)

           
            let options = {
            headers: {
              'content-type': 'multipart/form-data',
              'Authorization' : `Token ${localStorage.userToken}`
              }
            }
            vm.$http.post(vm.$url.concat('api/v1/books/patrons/'), data, options)
            .then(function(response){
              vm.$f7.popup.close("#add-patron")
              vm.get_patrons()
              // clear the data from the last patron
              vm.user_image = ''
              var canvas = document.getElementById('output')
              const context = canvas.getContext('2d');
              context.clearRect(0, 0, canvas.width, canvas.height);
              vm.new_patron_name = ''
            })

          },

        checkout_book(evt,book_id){

            // request to check out the book with the given id from the server
            var vm = this
            var token = localStorage.userToken
            vm.$http.patch(vm.$url.concat('api/v1/books/checkout/',book_id,'/',vm.current_patron),{},
            {headers:{Authorization: `Token ${token}`,}}, )
            .then(function (response) {
              if (response.status == 202){
                // show success
                vm.$f7.dialog.alert("Book checked out")
              }
              else if (response.status == 409){
                vm.$f7.dialog.alert("Book not available to check out")
              }
              console.log(response)
              vm.book_id = ''
            })
          .catch(function (error) {
            // show failure
            console.log(error);
            vm.$f7.dialog.alert("Book not available to check out")
          });
        },
        check_in_book(evt, book_id){
          // request to check in the book with the given id from the server
            var vm = this
            var token = localStorage.userToken
            vm.$http.patch(vm.$url.concat('api/v1/books/checkin/',book_id,'/',vm.current_patron),{},
            {headers:{Authorization: `Token ${token}`,} },  )
            .then(function (response) {
              console.log(response.status)
              if (response.status == 202){
                // display success message
                vm.$f7.dialog.alert("Book returned")
              }
              else if (response.status == 409){
                // display failure message
                vm.$f7.dialog.alert("Unable to find book")
              }
              console.log(response.status)
              vm.book_id = ''
            })
          .catch(function (error) {
            console.log('Error', error.status);
            vm.$f7.dialog.alert("Unable to find book")
          });

        },
        open_check(id){
            console.log(id)
            var vm = this
            vm.current_patron = id
            vm.$f7.popup.open('#book-check')
            vm.current_patron = id

        },
        
      read_barcode(){
        var vm = this
      var vid = document.getElementById('video')
      console.log(typeof(vid))
        console.log("Attempting to read barcode")
      // const codeReader = new BrowserBarcodeReader();
      if (vm.codeReader.isVideoPlaying(vid)){
        console.log("Video is already playing - stopping video")
        vm.codeReader.reset()
        if(vm.codeReader.isVideoPlaying(vid)){
          console.log("Unable to stop stream")
        }
        else{
          console.log("Stream stopped")
        }

      }
      
      vid.style.display = 'block'
      vm.codeReader
      .decodeOnceFromVideoDevice(undefined, 'video')
      .then(
        result => {
          console.log(result.text)
          vm.book_id = result.text
          // codeReader.stopStreams()
          vm.codeReader.reset()
          
          vid.style.display = 'none'
          })
      .catch(err => {console.error(err)
      // codeReader.stopStreams()
      vm.codeReader.reset()
      });
      },
      close_camera(){
        var vm = this
        if (vm.codeReader.isVideoPlaying(document.getElementById('video'))){
        vm.codeReader.reset()
        }
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        var vm = this

        // Call F7 APIs here
        // const faker = require('faker');
        // console.log(faker.image.avatar(), faker.name.firstName())
         setTimeout( this.get_patrons , 500)
            document.getElementById("user_image").addEventListener('change', function (event) {
              console.log("Compressing image file")
              vm.load_img(event)
            });


          });
    }
  }
</script>