
"use strict"

import { f7ActionsLabel } from 'framework7-vue/framework7-vue.esm.bundle'
import regeneratorRuntime from "regenerator-runtime";
var utils = {


  // print_label(isbn, text){
  //   // for reference: http://labelwriter.com/software/dls/sdk/docs/DYMOLabelFrameworkJavaScriptHelp/symbols/dymo.label.framework.html#.openLabelFile
  //   const http = require('axios')
  //   if(isbn == null){
  //     return
  //   }
  //   if(text == null){
  //     text = isbn
  //   }

  // var labelXml = `<?xml version="1.0" encoding="utf-8"?>
  // <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
  //   <PaperOrientation>Portrait</PaperOrientation>
  //   <Id>Small30334</Id>
  //   <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
  //   <DrawCommands>
  //     <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
  //   </DrawCommands>
  //   <ObjectInfo>
  //     <TextObject>
  //       <Name>TEXT</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
  //       <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
  //       <LinkedObjectName></LinkedObjectName>
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>True</IsVariable>
  //       <HorizontalAlignment>Center</HorizontalAlignment>
  //       <VerticalAlignment>Middle</VerticalAlignment>
  //       <TextFitMode>ShrinkToFit</TextFitMode>
  //       <UseFullFontHeight>True</UseFullFontHeight>
  //       <Verticalized>False</Verticalized>
  //       <StyledText>
  //         <Element>
  //           <String>Goodnight Moon</String>
  //           <Attributes>
  //             <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
  //             <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
  //           </Attributes>
  //         </Element>
  //       </StyledText>
  //     </TextObject>
  //     <Bounds X="152.1313" Y="179.2906" Width="2943.472" Height="462.1219"/>
  //   </ObjectInfo>
  //   <ObjectInfo>
  //     <BarcodeObject>
  //       <Name>BARCODE</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
  //       <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
  //       <LinkedObjectName></LinkedObjectName>
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>True</IsVariable>
  //       <Text>12345</Text>
  //       <Type>Ean13</Type>
  //       <Size>Medium</Size>
  //       <TextPosition>Bottom</TextPosition>
  //       <TextFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
  //       <CheckSumFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
  //       <TextEmbedding>None</TextEmbedding>
  //       <ECLevel>0</ECLevel>
  //       <HorizontalAlignment>Center</HorizontalAlignment>
  //       <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
  //     </BarcodeObject>
  //     <Bounds X="306.8188" Y="892.1813" Width="2589.219" Height="600"/>
  //   </ObjectInfo>
  // </DieCutLabel>`;

  // var parser, xmlDoc;

  //   parser = new DOMParser();
  //   xmlDoc = parser.parseFromString(labelXml,"text/xml");

  //   xmlDoc.getElementsByTagName("String")[0].innerHTML = text
  //   xmlDoc.getElementsByTagName("Text")[0].innerHTML = (isbn).toString().slice(0,12)

  //   var s = new XMLSerializer();
  //   var printStr = s.serializeToString(xmlDoc);
    
  //   console.log(xmlDoc.getElementsByTagName("String")[0])
  //   console.log(xmlDoc.getElementsByTagName("Text")[0])   
  //   var printerName = 'DYMO LabelWriter 450'

  //   // http.get('https://127.0.0.1:41951/DYMO/DLS/Printing/StatusConnected',{},{}).then(function(response){
  //   //   var xmlDoc = parser.parseFromString(response.data,"text/xml")
  //   //   // var printer = xmlDoc.getElementsByTagName("Name")[0].childNodes[0].nodeValue
  //   //   console.log(response)
     
  //   // })

  //   http.get('https://127.0.0.1:41951/DYMO/DLS/Printing/GetPrinters',{},{}).then(function(response){
  //     var xmlDoc = parser.parseFromString(response.data,"text/xml")
  //     var printer = xmlDoc.getElementsByTagName("Name")[0].childNodes[0].nodeValue
  //     if (printer){
  //       printerName = printer
  //     }
  //   }).catch(function(error){
  //     console.log(error)
  //   })

  //   let label = `printerName=${encodeURIComponent(printerName)}&printParamsXml=&labelXml=${encodeURIComponent(printStr)}&labelSetXml=`;

  //   http.post('https://127.0.0.1:41951/DYMO/DLS/Printing/PrintLabel',
  //   label,
  //   {})
  //   .then(function(response){
  //     console.log(response)
  //     return true
  //   }).catch(function(error){
  //     console.log("Couldn't print: ", error)
  //     return false

  //   })

  // },

  /* Utility function to convert a canvas to a BLOB  https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload */
  dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
  },
/* End Utility function to convert a canvas to a BLOB      */


async resize(e) {
  const vm = this
  const resize_width = 128;
  const reader = new FileReader();
  var file = e.target.files[0];

  reader.readAsDataURL(file);
  reader.name = file.name
  reader.size = file.size
  reader.onload = (event)=>{
    var img = new Image();//create a image
    img.src = event.target.result;//result is base64-encoded Data URI
    img.name = event.target.name;//set name (optional)
    img.size = event.target.size;//set size (optional)
    img.onload = function(el) {
      var elem = document.createElement('canvas');//create a canvas

      //scale the image to 600 (width) and keep aspect ratio
      var scaleFactor = resize_width / el.target.width;
      elem.width = resize_width;
      elem.height = el.target.height * scaleFactor;

      //draw in canvas
      var ctx = elem.getContext('2d');
      ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

      //get the base64-encoded Data URI from the resize image
      var srcEncoded = ctx.canvas.toDataURL(el.target, 'image/jpeg', 0);

      //assign it to thumb src
      // document.querySelector('#image').src = srcEncoded;

      return new Promise(function(resolve,reject){
          resolve({'img' : srcEncoded});
      })

      return srcEncoded
      /*Now you can send "srcEncoded" to the server and
      convert it to a png o jpg. Also can send
      "el.target.name" that is the file's name.*/

    }
  }
},

getIfDefined(prop, inners){
  var ret_val = prop
  try {
    inners.forEach(element => {
      ret_val = ret_val[element]
    });
    return ret_val
    
  } catch (error) {
    console.log(error)
    return ''
  }
}



}
export default utils