// Import Vue
import Vue from 'vue';
// const Vue = () => import('vue')
// Import Framework7
import Framework7 from './framework7-custom.js';
// const Framework7 = () => import('./framework7-custom.js')
// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import '../css/framework7-custom.less';


// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';

import utils from './utils.js'
// const lazyComponent = () => import('Component.vue')
import axios from 'axios'
// const axios = () => import('axios')

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

const env = process.env.NODE_ENV || 'development';
const root_url = (env === 'production' ? 'https://library.flyingw.net/' : 'http://127.0.0.1/')

// Vue.prototype.$url = 'http://127.0.0.1:8000/'
// Vue.prototype.$url = 'https://library.flyingw.net/'
Vue.prototype.$url = root_url
Vue.prototype.$http = axios
Vue.prototype.$token = ""
// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App,
    // util: utils
  },
});