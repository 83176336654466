

<template>
  <f7-page name="book-detail">
    <f7-navbar title="Book Detail" back-link="Back"></f7-navbar>




 <f7-col width="50">
          <f7-button @click="(evt) => {read_barcode(evt)}" fill raised popup-open="#get_isbn">Find by ISBN</f7-button>
        </f7-col>

    <!-- Popup -->
  <f7-popup id="get_isbn" @popup:close="(evt) => {close_camera(evt)}">
    <f7-view>
      <f7-page>
        <f7-navbar title="Find by ISBN">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <!-- <p>Enter ISBN here:</p> -->
          <f7-list-input
        id='isbn'
        type="text"
        placeholder="ISBN"
        :value="isbn"
            @input="isbn = $event.target.value"
      ></f7-list-input>
      <div>
        <video
          id="video"
          width="300"
          height="200"
          style="border: 1px solid gray"
        ></video>
      </div>
      <f7-button class="col" large fill raised color="red" @click="(evt) => {get_book_details(evt,isbn,)}">Submit</f7-button>
        </f7-block>
         </f7-page>
    </f7-view>
  </f7-popup>



    <f7-block-title>Book Detail</f7-block-title>
    <f7-list no-hairlines-md>
      <f7-list-input
        label="Title"
        type="text"
        placeholder="Book Title"
        :value="title"
        @input="title = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Author"
        type="text"
        placeholder="Author"
        :value="author"
        @input="author = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Publisher"
        type="text"
        placeholder="publisher"
        :value="publisher"
        @input="publisher = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Publish date"
        type="date"
        placeholder="Publish day"
        defaultValue="2014-04-30"
        :value="publish_date"
        @input="publish_date = $event.target.value"
      ></f7-list-input>

    </f7-list-input
    label="Location"
    type="text"
    placeholder="Where is it on the shelf?"
    ></f7-list-input>


      <f7-list-item
        title="Toggle"
      >
        <f7-toggle slot="after"></f7-toggle>
      </f7-list-item>

    
      <f7-list-input
        type="textarea"
        label="Additional Notes"
        placeholder="Notes"
      ></f7-list-input>
      
    </f7-list>

    

   <f7-row tag="p">
        <f7-button class="col" large fill raised color="red" @click="(evt) => {get_book_details(evt,isbn,)}">Cancel</f7-button>
        <f7-button class="col" large fill raised color="green" @click="(evt) => { barcode(evt, '0064430170', 'Goodnight Moon') }" >Submit</f7-button>
      </f7-row>
  </f7-page>
</template>

<script>

  import routes from '../js/routes.js';
  import utils from '../js/utils'
  import Vue from 'vue';
  import {BrowserBarcodeReader} from '@zxing/library'

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Book Worm', // App name
          theme: 'auto', // Automatic theme detection
          cache: false,
          // App root data
          data: function () {
            return {
             
             
            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },

        readerSize: {
        width: 640,
        height: 480
        },
        detecteds: [],

        isbn: '',
        password: '',
        book: {},
        title: '',
        author:'',
        publisher:'',
        publish_date:'',
        codeReader: new BrowserBarcodeReader(),

      }
    },
    methods: {
      print() {
        console.log("Testing again...")
      },
      barcode(isbn, title){
        utils.print_label(isbn, title)
      },
      get_isbn_by_barcode(data){
        console.log('detected',data)
      },

      get_book_details(evt,isbn1){
        var vm = this
        // reset the barcode reader
        vm.codeReader.reset()
        console.log("Getting isbn: ", isbn1)
        if (isbn1 == ''){
          return
        }
        this.$http.get("https://openlibrary.org/api/books?", {
            params: {
              bibkeys: 'ISBN:'.concat(isbn1),
              jscmd: 'data',
              format: 'json',
            }
          })
          .then(function (response) {
            console.log(((response.data)));
            var book = response.data['ISBN:'.concat(isbn1)]
            console.log(book)
            vm.title = book.title
            vm.author = book.authors[0].name
           
            vm.publisher = book.publishers[0].name

            if (book.publish_date.length == 4){
              vm.publish_date = book.publish_date.concat('-01-01')
            }
            else{
              vm.publish_date = book.publish_date
            }

          })
          .catch(function (error) {
            console.log(error);
          });
      },
      read_barcode(){
        var vm = this
      var vid = document.getElementById('video')
      console.log(typeof(vid))
        console.log("Attempting to read barcode")
      // const codeReader = new BrowserBarcodeReader();
      if (vm.codeReader.isVideoPlaying(vid)){
        console.log("Video is already playing - stopping video")
        vm.codeReader.reset()
        if(vm.codeReader.isVideoPlaying(vid)){
          console.log("Unable to stop stream")
        }
        else{
          console.log("Stream stopped")
        }

      }
      
      vid.style.display = 'block'
      vm.codeReader
      .decodeOnceFromVideoDevice(undefined, 'video')
      .then(
        result => {
          console.log(result.text)
          vm.isbn = result.text
          // codeReader.stopStreams()
          vm.codeReader.reset()
          
          vid.style.display = 'none'
          })
      .catch(err => {console.error(err)
      // codeReader.stopStreams()
      vm.codeReader.reset()
      });
      },
      close_camera(){
        var vm = this
        if (vm.codeReader.isVideoPlaying(document.getElementById('video'))){
        vm.codeReader.reset()
        }
      }
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
      });
    }
  }

  // .listVideoInputDevices()
  // .then(videoInputDevices => {
  //   videoInputDevices.forEach(device =>
  //     console.log(`${device.label}, ${device.deviceId}`)
  //   );
  // })
  // .catch(err => console.error(err));


</script>