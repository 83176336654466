
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import FormPage from '../pages/form.vue';
import BookDetailPage from '../pages/book-detail.vue';
import AddBookPage from '../pages/add-book.vue'
import PatronPage from '../pages/patron.vue'
import BookListPage from '../pages/book-list.vue'
import CheckedBooksPage from '../pages/checked-books.vue'

import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/book-detail/',
    component: BookDetailPage,
  },
  {
    path: '/add-book/',
    component: AddBookPage,
  },
  {
    path: '/patron/',
    component: PatronPage,
  },
  {
    path: '/book-list/',
    component: BookListPage,
  },
  {
    path: '/checked-books/',
    component: CheckedBooksPage,
  },
  
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;

