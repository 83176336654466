

<template>
  <f7-page name="book-list">
    <f7-navbar title="Book List" back-link="Back"></f7-navbar>



  <div >

    <!-- <div class="menu-inner" >
          <div class="menu-item menu-item-dropdown" id="searchby-selector">
        <div class="menu-item-content">Author</div>
        <div class="menu-dropdown menu-dropdown-right" >
          <div class="menu-dropdown-content" >
            <a href="#" class="menu-dropdown-link menu-close">Title</a>
            <div class="menu-dropdown-divider"></div>
            <a href="#" class="menu-dropdown-link menu-close">Author</a>
          </div>
        </div>
      </div>
      </div> -->

       <f7-popup popup-swipe-to-close id='search-by' style="height:auto;" >
        <f7-block-title id="search-title">Search By</f7-block-title>
          <f7-list>
            <f7-list-item radio title="Title" name="demo-radio" checked
            value="Title"
            @change="searchby_change($event.target.value)" ></f7-list-item>

            <f7-list-item radio title="Author" name="demo-radio" 
            value="Author"
            @change="searchby_change($event.target.value)"></f7-list-item>

             <f7-list-item radio title="Date" name="demo-radio"
            value="Date"
            @change="searchby_change($event.target.value)"></f7-list-item>

          </f7-list>
       </f7-popup>

       <a data-popup="#search-by" class="button popup-open"  id='searchby-selector' href="#">Title</a>



      <f7-searchbar
        :init=true
        id='book-search'
        search-container=".search-list"
        search-in=".item-title"
        :disable-button="!$theme.aurora"
        :hide-dividers=false
        ></f7-searchbar>

  </div>
  <div >
    <div>
      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <f7-list media-list class="search-list searchbar-found">
                    <f7-list-item media-item
                    :title = "book.title" 

                    v-for="book in books"
                    :key="book.lib_id"
                    @click="book_detail(book.lib_id)"
                    :subtitle = "book.author"
                    :after = "book.pub_date"

                    >
                    </f7-list-item>
        </f7-list>
        </div>
  </div>

    <!-- Book detail popup -->
    <f7-popup popup-swipe-to-close id='book-detail'>
      <f7-block-title>Book Detail</f7-block-title>
      <f7-list id='book-items'>
        <div 
        v-for="item in selected_book"
        :key="item[0]">
        <div class="list-item">
          <p v-if="item[0] != 'Cover_art'"> {{item[0]}} : {{item[1]}} </p>
          <img v-else :src="item[1]" alt='Cover Art' id='book-image'></img>
        </div>
        </div>
      </f7-list>
      <f7-button popup-close>Close</f7-button>
      <f7-button popup-close @click=delete_book()>Delete</f7-button>
    </f7-popup>


  </f7-page>
</template>






<style scoped>
  .book-panel{
    height: 20px;
    margin: 10px;
  }
  /* .books{
    /* background-color: beige; 
  } */
  #book-detail{
    /* TODO: fix this so that it's always aligned center of the screen */
    text-align: center;
    padding:1.5rem;
    /* overflow: auto; */
    /* margin: 0 auto; */
    max-height: 90%;
    height: fit-content;
    width: 70%;
    max-width: 500px;
    

    
  }
  
#book-items{
  line-height: 1.2em;
  padding: .1px;
  margin: 1px;
  overflow: auto;
  
}
#book-image{
  height: 24vh;
}
#book-search{
  width: 80%;
}
#searchby-selector{
  position: absolute;
  width:17%;
  right:0.5%;
  height:48px;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 8px;
  overflow: visible;
  
  /* bottom: 50%; */
 
}
</style>

<script>

  import routes from '../js/routes.js';
  import utils from '../js/utils'
  import Vue from 'vue';
  import { f7, f7ready } from 'framework7-vue';
import Dom7 from 'dom7';
  // import {BrowserBarcodeReader} from '@zxing/library'

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Book Worm', // App name
          theme: 'auto', // Automatic theme detection
          cache: false,
          // App root data
          data: function () {
            return {
             
             
            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },

        // readerSize: {
        // width: 640,
        // height: 480
        // },
        detecteds: [],

        isbn: '',
        password: '',
        book: {},
        title: '',
        author:'',
        publisher:'',
        publish_date:'',
        books:[],
        selected_book: [],
        book_id:0,
        searchby:'',

      }
    },
    methods: {

      // triggered whenever the search by box is changed, should resort the 
      // book list and change the parameter that it is searched by.
      searchby_change(evt){
        var vm = this
        console.log(evt)
        var label = document.getElementById('searchby-selector')
        label.innerHTML = evt
        var sortFun = function(){}

        var search = document.getElementById('book-search')
        console.log(vm.$f7.searchbar.disable().params.searchIn)
        if (evt == "Author"){
          vm.$f7.searchbar.disable().params.searchIn = ".item-subtitle"
          sortFun = function(a,b){
            if (a.author & b.author){
              return a.author.localeCompare(b.author)
            }
            else if(a.author){
              return 1
            }
            return -1
            }
        }
        else if(evt == 'Title'){
          vm.$f7.searchbar.disable().params.searchIn = ".item-title"
          sortFun = function(a,b){
            return a.title.localeCompare(b.title)}
        }
        else if(evt == "Date"){
          vm.$f7.searchbar.disable().params.searchIn = ".item-after"
          sortFun = function(a,b){
             if (a.pub_date & b.pub_date){
              return a.pub_date.localeCompare(b.pub_date)
            }
            else if(b.pub_date){
              return -1
            }
            return 1
            }
        }
        vm.books.sort(sortFun)
        vm.$f7.popup.close('#search-by')

      },
      
      delete_book(){
        var token = localStorage.userToken
        var vm = this
        vm.$http.delete(vm.$url.concat('api/v1/books/'.concat(vm.book_id)),{
          headers:{Authorization: `Token ${token}`,}}, )
       .then(function (response) {
        var index =  vm.books.findIndex(function(elm){return elm.id == vm.book_id})
        if (index == 0){
          vm.books.shift()
        }
        else if(index == (vm.books.length - 1)){
          vm.books.pop()
        }
        else{
          vm.books.splice(index,1)
        }



        console.log("Index: ", index)
         console.log(response)
       })
      },

      load_books(){
        // retrieve the list of books, display loading sign while fetching
        const self = this;
        self.$f7.dialog.preloader('Retrieving Books');
        var token = localStorage.userToken
        self.$http.get(self.$url.concat('api/v1/books/'),{
          headers:{Authorization: `Token ${token}`,}}, )
       .then(function (response) {
          self.$f7.dialog.close();
          response.data.forEach(book =>{
            self.books.push(JSON.parse(JSON.stringify(book)))
          })
          self.books.sort(function(a,b){return a.title.localeCompare(b.title)})

          console.log(self.books)
        })
        .catch(function (error) {
          console.log(error);
          self.$f7.dialog.close();
          
          var notificationClickToClose = self.$f7.notification.create({
            icon: '<i class="icon demo-icon">7</i>',
            title: 'Library',
            titleRightText: 'now',
            subtitle: 'Unable to load book list',
            text: 'Please reload the page to try again',
            closeOnClick: true,
          }).open()
        });
 
      },

      book_detail(id){
        var vm = this
        // get the book from the list
        var book = vm.books.find(function(bk){return bk.lib_id == id})
        vm.book_id = id
        console.log("Book: ", book, "  ID: ", id)
        if (book.cover_art){
          console.log(book.cover_art)
          document.getElementById('book-detail').style.backgroundImage = book.cover_art
        }
        var entries = Object.entries(book)
        console.log(entries)
        vm.selected_book = []
        var newEntry = []
        entries.forEach( entry => {
          if(entry[1]){
            console.log("entry: ", entry)


            entry.forEach( (val, index) =>{
              if (typeof(val) == 'string' && val[0].match("^[a-zA-Z\(\)]+$")){
                newEntry[index] = val[0].toUpperCase() + val.slice(1)
              }
              else{
                newEntry[index] = val
              }
            })
            vm.selected_book.push(newEntry)
            newEntry = []
          }
        })

        vm.$f7.popup.open("#book-detail")  
        
      },
      
     
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
        this.load_books()

        

      });
    }
  }



</script>