

<template>
  <f7-page name="add-book">
    <f7-navbar title="Add Book" back-link="Back"></f7-navbar>




 <f7-col width="50">
          <f7-button class='col' @click="(evt) => {read_barcode(evt)}" med fill raised popup-open="#get_isbn">Find by ISBN</f7-button>
          <f7-button class='col' @click="(evt) => {get_books_by_filter(evt)}" med fill raised popup-open="#book_results">Find by Title or Author</f7-button>
        </f7-col>

    <!-- Popup -->
  <f7-popup id="get_isbn" @popup:close="(evt) => {close_camera(evt)}">
    <f7-view>
      <f7-page>
        <f7-navbar title="Find by ISBN" >
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div id='vid-container'>
        <video
            id="video"
            width="280"
            height="200"
          ></video>
        </div>
        <f7-block>
          <f7-input 
         
        id='isbn'
        type="number"
        placeholder="ISBN or LCCN"
        :value="isbn"
        @input="isbn = $event.target.value"

      ></f7-input>
      
      <f7-button style="width=80%;" class="col" med fill raised color="green" @click="(evt) => {get_book_details(evt,isbn,)}">Submit</f7-button>
        </f7-block>
         </f7-page>
    </f7-view>
  </f7-popup>

  <!-- Search for book by whatever info is filled in on the page -->
   <f7-popup id="book_results" @popup:close="(evt) => {}">
    <f7-view>
      <f7-page>
        <f7-navbar title="Select Book to autofill data" >
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
      <f7-block-title>Book Results</f7-block-title>
      <f7-list media-list>
         <f7-list-item media-item
                    :title = "book.title" 

                    v-for="book in search_books"
                    :key="book.id"
                    @click="fill_book(book)"
                    :subtitle = "book.author"
                    :after = "book.publisher"
                    >
          <template #media>
             <img :src="book.imageLinks.smallThumbnail" />
          </template>
        </f7-list-item>
      </f7-list>
      <!-- <f7-button style="width=80%;" class="col" med fill raised color="green" @click="(evt) => {get_book_details(evt,isbn,)}">Select Book</f7-button> -->
        </f7-block>
         </f7-page>
    </f7-view>
  </f7-popup>



    <f7-block-title>Book Detail</f7-block-title>
    <f7-list no-hairlines-md>
      <f7-list-input
        label="Title"
        type="text"
        placeholder="Book Title"
        :value="title"
        @input="title = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Author"
        type="text"
        placeholder="Author"
        :value="author"
        @input="author = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Publisher"
        type="text"
        placeholder="publisher"
        :value="publisher"
        @input="publisher = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Publish date"
        type="date"
        placeholder="Publish day"
        defaultValue="01-21-2014"
        :value="publish_date"
        @input="publish_date = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="ISBN"
        type="number"
        placeholder="ISBN or LCCN"
        defaultValue=""
        :value="isbn"
        @input="isbn = $event.target.value"
      ></f7-list-input>



      <f7-list-input
      label="Location"
      type="text"
      placeholder="Where is it on the shelf?"
      :value="location"
      @input="location = $event.target.value"
      ></f7-list-input>

     
      
    </f7-list>
     <f7-list no-hairlines-md>
        <f7-list-input
          label="Library ID"
          type="number"
          defaultValue=""
          placeholder="Unique Identifier"
          :value="lib_id"
          
        ></f7-list-input>
     </f7-list>

<!-- :input="false" -->
    

   <f7-row tag="p">
        <!-- <f7-button class="col" large fill raised color="red" @click="(evt) => {}">Cancel</f7-button> -->
        <f7-button class="col" large fill raised color="green" @click="(evt) => {  submit_book(evt)}" >Submit</f7-button>
        <f7-button id='printBarcode' class="col" large fill raised color="blue" @click="(evt) => {barcode()}">Print barcode</f7-button>

      </f7-row>
  </f7-page>
</template>

<style scoped>
 #vid-container{
    border: 1px solid white; 
    margin: 10px auto; 
    width: 280px;
  }
  #isbn{
    margin:5px auto;
    width: 50%;
  }
  .col{
    margin: 5% auto;
     width:80%;
  }
</style>


<script>

  import routes from '../js/routes.js';
  import utils from '../js/utils'
  import Vue from 'vue';
  import {BrowserBarcodeReader} from '@zxing/library'

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Book Worm', // App name
          theme: 'auto', // Automatic theme detection
          cache: false,
          // App root data
          data: function () {
            return {
             
             
            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },

        readerSize: {
        width: 640,
        height: 480
        },
        detecteds: [],

        isbn: NaN,
        lib_id:NaN,
        password: '',
        book: {},
        title: '',
        author:'',
        publisher:'',
        publish_date:'',
        search_books:[],
        image:'',
        location:'',
        codeReader: new BrowserBarcodeReader(),

      }
    },
    mounted() {
    },
    methods: {
      submit_book(){
        var vm = this
        var token = localStorage.userToken
        console.log(token)
        vm.$http.post(vm.$url.concat('api/v1/books/'), {
          isbn: vm.isbn,
          title: vm.title,
          author: vm.author,
          publisher: vm.publisher,
          pub_date: vm.publish_date,
          location: vm.location,
       
          // edition: "",
          cover_art: vm.image,
          // patron: null

        },
       
        {
          headers:{
          Authorization: `Token ${token}`,
          }
        },
        )
        .then(function (response) {
          vm.lib_id = response.data.lib_id
          if (response.status == 201){
            // clear the entry fields and notify user of success
            if (response.data.lib_id){
              // console.log(response.data.isbn, vm.isbn)
              vm.lib_id = response.data.lib_id
              
              document.getElementById("printBarcode").classList.remove('disabled')

              var notifySuccess = vm.$f7.notification.create({
              icon: '<i class="icon demo-icon"></i>',
              title: 'Library',
              titleRightText: 'now',
              subtitle: 'Book Submitted',
              text: 'Please print a barcode',
              closeOnClick: true,
              closeTimeout: 2000,
               }).open()
            }
            else{
               var notifySuccess = vm.$f7.notification.create({
              icon: '<i class="icon demo-icon"></i>',
              title: 'Library',
              titleRightText: 'now',
              subtitle: 'Book Submitted',
              // text: 'Please reload the page to try again',
              closeOnClick: true,
              closeTimeout: 900,
            }).open()

              // only clear this if no barcode is needed
              vm.isbn = 0
              vm.title = ''
              vm.author = ''
              vm.publisher = ''
              vm.publish_date = ''
              vm.image = ''
              vm.lib_id = ''
           
            }
            
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      },

      barcode(){
        var vm = this
        var token = localStorage.userToken
        console.log("Trying to Print:",vm.lib_id, vm.title)
        // utils.print_label(vm.isbn, vm.title)
        vm.$http.post(vm.$url.concat('api/v1/books/barcode/', vm.lib_id), {
          // isbn: vm.isbn,
          // title: vm.title,
        },
       
        {
          headers:{
          Authorization: `Token ${token}`,
          }
        },
        )
        .then((res)=>{
          console.log(res)

          // check for success, then reset all input fields
          vm.isbn = 0
          vm.title = ''
          vm.author = ''
          vm.publisher = ''
          vm.publish_date = ''
          vm.image = ''
          vm.lib_id = 0

        }).catch(function (error) {

            if (error.response.status == 503){
               var notifyFailure = vm.$f7.notification.create({
              icon: '<i class="icon demo-icon"></i>',
              title: 'Library',
              titleRightText: 'now',
              subtitle: 'Error',
              text: 'Printer Offline',
              closeOnClick: true,
              closeTimeout: 4000,
               }).open()
            }
          });

      },
      get_isbn_by_barcode(data){
        console.log('detected',data)
      },

      // https://www.googleapis.com/books/v1/volumes?q=intitle:lord+ring+fellowship+inauthor:tolkien    intitle isbn
      get_books_by_filter(evt){
        var vm = this
        var params = {}
        if (vm.title){
          params['intitle'] = vm.title
        }
        if (vm.author){
          params['inauthor'] = vm.author
        }
        if (vm.publisher){
          params['']
        }

        var url = (params)=>{
            var res = '';  
            for (let [key, value] of Object.entries(params)) {
                res += (`${key}:${value.replace(/ /g,'+')}+`);
            } 
            return res;
          }


        vm.$http.get("https://www.googleapis.com/books/v1/volumes?q=" + url(params)).then(function(resp){
          console.log(resp.data.items)
          console.log(resp.data.totalItems)
          // item.volumeinfo: authors publisher publishedDate title imageLinks.smallThumbnail

          resp.data.items.forEach(book =>{
            vm.search_books.push(JSON.parse(JSON.stringify(book.volumeInfo)))
            // self.books.sort(function(a,b){return a.title.localeCompare(b.title)})
          })

          

        })

      },

      fill_book(book){
        var vm = this;
        console.log(book)
        vm.title = book.title
        vm.author = book.authors[0]
        vm.publisher = utils.getIfDefined(book, ['publisher'])
        vm.publish_date = new Date(book.publishedDate).toISOString().slice(0,10)
        vm.image = utils.getIfDefined(book, ['imageLinks', 'thumbnail'])
        console.log(book.industryIdentifiers[0])
        vm.isbn = Number(book.industryIdentifiers[0].identifier)
        vm.$f7.popup.close()
      },

      get_book_details(evt,isbn1){
        var vm = this
        // reset the barcode reader
        vm.codeReader.reset()
        vm.$f7.popup.close()
        if (isbn1 == ''){
          return
        }
        document.getElementById("printBarcode").classList.remove('disabled')


        function openLib(){
          console.log("Called openlib")
             if (vm.title.length < 2){
              //  console.log(vm.title)
             vm.$http.get("https://openlibrary.org/api/books?", {
              params: {
              bibkeys: 'ISBN:'.concat(isbn1),
              jscmd: 'data',
              format: 'json',
                }
              })
              .then(function (response) {
                if(response.data['ISBN:'.concat(isbn1)]){
                  var book = response.data['ISBN:'.concat(isbn1)]
                  console.log(book)
                  vm.title = book.title
                  vm.author = utils.getIfDefined(book, ['authors', 0, 'name'])
                  vm.image = utils.getIfDefined(book, ['cover', 'medium'])
                  
                
                  vm.publisher = utils.getIfDefined( book, ['publishers', 0, 'name'])
                  vm.publish_date = new Date(book.publish_date).toISOString().slice(0,10)

                }
              }).catch(function (error) {
                console.log(error);
              });
          }

        }



         vm.$http.get("https://www.googleapis.com/books/v1/volumes?", {
                params: {
                  q:'isbn:'.concat(isbn1),
                }
              })
            .then(function (response) {
              console.log(response)
              if (response.data.items){
              var book = response.data.items[0].volumeInfo
              console.log(book)
              vm.title = book.title
              vm.author = book.authors[0]
              vm.publisher = utils.getIfDefined(book, ['publisher'])
              vm.publish_date = new Date(book.publishedDate).toISOString().slice(0,10)
              vm.image = utils.getIfDefined(book, ['imageLinks', 'thumbnail'])
              
              }
              openLib()
            }).catch(function(error){
              console.log(error)
              console.log("Trying open library api")
              openLib()
             
          })
        vm.isbn = isbn1
        if(!evt.from_barcode){
          console.log("Not initiated by barcode")
          // prompt to print a barcode on save
        }
      },
      read_barcode(){
        var vm = this
        var vid = document.getElementById('video')
        console.log(typeof(vid))
          console.log("Attempting to read barcode")
        // const codeReader = new BrowserBarcodeReader();
        if (vm.codeReader.isVideoPlaying(vid)){
          console.log("Video is already playing - stopping video")
          vm.codeReader.reset()
          if(vm.codeReader.isVideoPlaying(vid)){
            console.log("Unable to stop stream")
          }
          else{
            console.log("Stream stopped")
          }

        }
        
        vid.style.display = 'block'
        vm.codeReader
        .decodeOnceFromVideoDevice(undefined, 'video')
        .then(
          result => {
            console.log(result.text)
            vm.isbn = result.text
            vm.get_book_details({'from_barcode':true}, vm.isbn)
            // codeReader.stopStreams()
            vm.codeReader.reset()
            
            vid.style.display = 'none'
            })
        .catch(err => {console.error(err)
        // codeReader.stopStreams()
        vm.codeReader.reset()
        });
      },
      close_camera(){
        var vm = this
        if (vm.codeReader.isVideoPlaying(document.getElementById('video'))){
        vm.codeReader.reset()
        }
        // clear out the isbn field here as well
        vm.isbn = ''
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        console.log("F7 ready")
        document.getElementById("printBarcode").classList.add('disabled')

        // Call F7 APIs here
      });
    }
  }

</script>