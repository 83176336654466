<template>
<f7-app :params="f7params" >

  <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Menu"></f7-navbar>
        <f7-block>
          <!-- Add right panel links here -->

          <!-- Add book -->
          <!-- Check out books -->
          <f7-list>
            <f7-list-item @click="logout" title="Logout"></f7-list-item>
            <f7-list-item link="/add-book/" title="Book Entry"></f7-list-item>
            <f7-list-item link="/checked-books/" title="Checked Out Books"></f7-list-item>
          <f7-list-item @click="get_book_list" title="Download Book List"></f7-list-item>
          <f7-list-item @click="setup_printer" title="Download Label Printer Software"></f7-list-item>

          </f7-list>
          <!--  -->

        </f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>

  <f7-login-screen id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>Login</f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            type="text"
            name="username"
            placeholder="Your username"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Your password"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button title="Sign In"  @click="alertLoginData"></f7-list-button>
          <f7-block-footer>
           <!-- <f7-list-button title="Admin View"  @click="load_admin"></f7-list-button> -->
           <!-- <f7-link  href="/api/theboss">Reset password</f7-link> -->
           <a class="link external" href="https://library.flyingw.net/api/theboss">Admin View</a>
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  import utils from '../js/utils.js';
 

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Library', // App name
          theme: 'auto', // Automatic theme detection
          cache: false,
          // App root data
          data: function () {
            return {
              user: {
                firstName: 'John',
                lastName: 'Doe',
              },

            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },

        // Login screen data
        username: '',
        password: '',
      }
    },
    methods: {
      checkToken() {  // returns true if stored token is still valid with server
        // TODO:  Create endpoint on server to check token, and refresh if possible?


        return false;
      },


      alertLoginData() {
         
        const vm = this
        // submit the username and password to the site, then save the returned token if successful
        vm.$http.post(vm.$url.concat('api/api-token-auth/'), {
          username: vm.username,
          password: vm.password,


        },
        )
        .then(function (response) {
          console.log(response);
          console.log(response.data.token)
          vm.$token = response.data.token
          // close the login screen
          vm.$f7.loginScreen.close()
          localStorage.userToken = vm.$token
         


        })
        .catch(function (error) {
          console.log(error);
          // display the error to the user
          localStorage.removeItem('userToken');  // if we failed to login, make sure it's not because of a bad cookie
          vm.$f7.dialog.alert("Incorrect Login Credentials");
        });
      },
      load_admin() {
        window.open("http://" +  vm.$url.concat("/api/theboss/"))
      },
      logout() {
        localStorage.removeItem('userToken')
        this.$f7.loginScreen.open("#my-login-screen")
      },
      setup_printer(){
        const vm = this
        var token = localStorage.userToken
        vm.$http.get(vm.$url.concat('printer_app'), {
          responseType: 'blob' ,
          headers:{Authorization: `Token ${token}`,}}, )
        .then(function (response) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "books.csv"
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)

      },

      get_book_list(){
        const vm = this
        var token = localStorage.userToken
        vm.$http.get(vm.$url.concat('api/v1/books/db_as_csv'), {
          responseType: 'blob' ,
          headers:{Authorization: `Token ${token}`,}}, )
        .then(function (response) {
        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "books.csv"
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
      },

    },
    mounted() {
      this.$f7ready((f7) => {
        const vm = this
        if (!localStorage.userToken){ // this test should be updated to check that the cookie is still good, not just that it exists
          f7.loginScreen.open("#my-login-screen")
          console.log("We need to login")
        }
        else{
          // TODO: check to see if token is still valid

          vm.$token = localStorage.userToken

          vm.$f7.loginScreen.close()
          // console.log(f7.$token)
        }

        // Call F7 APIs here
      });
    }
  }
</script>